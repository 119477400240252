import request from 'superagent';
import { API_URL } from './url';
import cogoToast from 'cogo-toast';

class Request {
    async get(method, data) {
		let auth = localStorage.getItem('aroos_user');
		if (auth) {
			auth = JSON.parse(auth);
		}
        const res = await request
            .get(`${API_URL}${method}`)
            .query(data)
			.set('api_key', 'aroos_1309')
			.set('auth', auth? auth.token : '')
            .set('Accept', 'application/json')
            .then(res => {
                return res.body || { empty: true };
            })
            .catch(err => {
				if (err.status === 403) {
					err.message = 'Error de permisos.';
				}
				cogoToast.error(err.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
                return { error: true, message: err.message};
            });
        return res;
	}

    async post(method, data) {
		let auth = localStorage.getItem('aroos_user');
		if (auth) {
			auth = JSON.parse(auth);
		}
        const res = await request
            .post(`${API_URL}${method}`)
            .send(data)
			.set('api_key', 'aroos_1309')
			.set('auth', auth? auth.token : '')
            .set('Accept', 'application/json')
            .then(res => {
                return res.body || { empty: true };
            })
            .catch(err => {
				if (err.status === 403) {
					err.message = 'Error de permisos.';
				}
				cogoToast.error(err.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
                return { error: true, message: err.message};
            });
        return res;
    }
};

export default Request;