function makeId(array, keyName) {
    if (Array.isArray(array)) {
		let id = array.length + 1;

		let exist = true;
		while(exist) {
			const findId = array.find(element => element[keyName] == id);

			if (!findId) {
				exist = false;
			} else {
				id++;
			}
		}

		return id;
	}
}

module.exports = {
	makeId
};