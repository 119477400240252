import Request from '../../api/apiService';
const req = new Request();

export function loginSuccess (event) {
    localStorage.setItem("aroos_user", JSON.stringify(event));
    this.setState({ auth: true, token: event.token });
}

export function logOut () {
	window.localStorage.setItem("aroos_user", '{"auth": false}');
	localStorage.removeItem('aroos_modulos');
    this.setState({auth: false, token: undefined});
    window.location = "/";
}

export async function getId(token) {
    if (token) {
        const res = await req.post('usuario/get/id', { token });
        if(res.id_usuario) {
            return res.id_usuario;
        }
    }
}

export async function getPermisos(token) {
    if (token) {

		const res = await req.post('usuario/permisos', { token });

        if(res.token && res.permisos) {
            this.setState({permisos: res.permisos, token: res.token});
            let session = JSON.parse(window.localStorage.getItem("aroos_user"));
            localStorage.setItem("aroos_user", JSON.stringify({...session, token: res.token}));
            return res.permisos;
        }
        return [];
    } else {
        return [];
    }
}

export async function loadUser() {
  
    let session = JSON.parse(window.localStorage.getItem("aroos_user"));

    const data = {
        token: session.token
    }
    const user = await req.post('decode', data);

    return user;
    
};