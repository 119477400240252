function formatNumber(number, decPlaces, decSep, thouSep, symbol) {
    let dec = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    let sep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(dec)));
    var j = (j = i.length) > 3 ? j % 3 : 0;
    
    return (symbol? (symbol+' ') : '') + sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\sep{3})(?=\sep)/g, "$1" + thouSep) +
        (dec ? sep + Math.abs(number - i).toFixed(dec).slice(2) : "");
}

module.exports = {
	formatNumber
};